import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import {Link, useHistory} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import {httpCall} from "../services/httpCall";
import {NotificationManager} from "react-notifications";

const Header = () => {
  const router = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);
  const [date, setDate] = useState();
  const getDate = () => {
    httpCall({url: 'admin/recent-month-leaderboard', options: {method: 'GET'}}).then(({data}) => {
      if (data.recentMonthLeaderboard.length) {
        setDate(new Date(data.recentMonthLeaderboard[0].createdAt));
      }
    })
  }
  useEffect(() => {
    getDate()
  }, [])
  return (<><Navbar bg="light" className='w-100' expand="lg">
    <Navbar.Brand className={'bangers no-select'}>Dashoboard</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="w-100">
        <Link className='nav-link' to="/studentsList"><span>Week Videos</span></Link>
        <Link className='nav-link' to="/createSeason"><span>Create Season</span></Link>
        <Link className='nav-link' to="/createChallenge"><span>Create Challenge</span></Link>
        <Link className='nav-link' to="/users"><span>Users</span></Link>
        <Link className='nav-link' to="/report"><span>Reports</span></Link>
        <span className='ml-auto btn btn-outline-dark' onClick={() => {
          setShowConfirm(true)
        }}>Month Leaderboard</span>
        <span className='ml-3 btn btn-outline-dark' onClick={() => {
          localStorage.removeItem('token')
          router.push('/login');
        }}>Log out</span>
      </Nav>

    </Navbar.Collapse>
  </Navbar>
    <Modal show={!!showConfirm} onHide={() => setShowConfirm(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure to send monthly leaderboard now?
        {date && <p>last sent date is {date.toLocaleDateString('en-GB')} ({formatDistanceToNow(date)})</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowConfirm(false)}>
          No
        </Button>
        <Button variant="primary" onClick={() => {
          setShowConfirm(false);
          httpCall({
            url: 'admin/monthly-leaderboard',
            options: {method: 'GET'}
          }).then(({data}) => {
            getDate();
            NotificationManager.info('Gift emails are sent to this month winners','Info');
          })
        }}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  </>)
}
export default Header;