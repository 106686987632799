import React, {useEffect, useState} from "react";
import Fade from "react-reveal/Fade";
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import { useHistory } from "react-router-dom";

import Header from "./Header";
import {httpCall} from "../services/httpCall";

const Report=()=>{
  const [usersCount,setUsersCount]=useState('');
  const [videosCount,setVideosCount]=useState('');
  const [challengesCount,setChallengesCount]=useState('');
  const router = useHistory();

  const convertToMOrK=(value)=>{
    {
      if(value>=1000000)
      {
        value=(value/1000000).toFixed(2)+"M"
      }
      else if(value>=1000)
      {
        value=(value/1000).toFixed(2)+"K";
      }
      return value;
    }
  }
  useEffect(()=>{
    const token = localStorage.getItem("token");
    if (!token) {
      return router.push("/login");
    }
    httpCall({url:'admin/resourcesCount',options:{method:'GET'}}).then((res)=>{
      const data=res.data;
      setUsersCount(convertToMOrK(data.usersCount));
      setVideosCount(convertToMOrK(data.videosCount));
      setChallengesCount(convertToMOrK(data.challengesCount));
    })
  },[]);
  return<div className="container-login100 align-items-baseline">
    <Header/>
    <Fade top cascade><div className="wrap-login100 text-center w-900">
      <CardDeck>
        <Card className={'pointer'} onClick={()=>router.push('/users')}>
          <Card.Body>
            <Card.Title>Users Count</Card.Title>
            <Card.Text>
              <img src="/user.png" width={25} alt="user"/>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{usersCount}</small>
          </Card.Footer>
        </Card>
        <Card className={'pointer'} onClick={()=>router.push('/studentList')}>
          <Card.Body>
            <Card.Title>Videos Count</Card.Title>
            <Card.Text>
              <img src="/video.png" width={25} alt="video"/>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{videosCount}</small>
          </Card.Footer>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Challenges Count</Card.Title>
            <Card.Text>
              <img src="/calendar.png" width={25} alt="week"/>
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">{challengesCount}</small>
          </Card.Footer>
        </Card>
      </CardDeck>
    </div>
    </Fade></div>
};
export default Report;