import React, {useEffect, useState} from "react";
import Header from "./Header";
import Fade from "react-reveal/Fade";
import { httpCall } from "../services/httpCall";
import Select from "react-select";
import { CHALLENGES_TYPE } from "../config";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ProgressBar from 'react-bootstrap/ProgressBar'

const CreateChallenge = () => {
  const [challengeType, setChallengeType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [upload, setUpload] = useState(0);
  const router = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      router.push("/login");
    }
  }, []);
  const options = [
    { value: CHALLENGES_TYPE.DEFAULT, label: "Normal" },
    { value: CHALLENGES_TYPE.FOOTBAL, label: "Football" },
    { value: CHALLENGES_TYPE.TRIATHLON, label: "Triathlon" },
    { value: CHALLENGES_TYPE.EGG, label: "Egg" },
    { value: CHALLENGES_TYPE.WATER, label: "Water" }
  ];
  const handleCreateChallenge = () => {
    if(!file.size)
      return NotificationManager.warning('Video is required!','Error')


      const fileExt = file.name.split(".");
      if(!loading){

      return httpCall({
        url: "admin/challenges",
        data: {
          name,
          description,
          challengeType,
          serveOriginal:true,
          metadata: {
            vidExt: fileExt[fileExt.length - 1].toLowerCase(),
            contentType: file.type
          }
        }
      }).then((res) => {
        axios.put(res.data.signedURL,file,{
          onUploadProgress: progressEvent => {
            setUpload(progressEvent.loaded/progressEvent.total*100);
          },
          headers:{
            'Content-Type':file.type
          }
        }).then(()=>{
          setLoading(false);
          NotificationManager.info('Video uploaded successfully','Info');
        router.push("/studentsList");
        }).catch(()=>{
          setLoading(false);
        })
      });
      }
  };
  return (
    <>
      <div className="container-login100 align-items-baseline">
        <Header />
        <Fade top cascade>
          <div className="wrap-login100 text-center">
            <h3 className="bangers">Create Challenge (Week)</h3>
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="Name"
            />
            <textarea
              value={description}
              onChange={e => setDescription(e.target.value)}
              rows={4}
              className="form-control mb-3 no-resize"
              placeholder="Description"
            />

            <Select
              className="mb-3"
              options={options}
              placeholder={"Normal"}
              onChange={data => {
                setChallengeType(data.value);
              }}
            />
            <label className="pointer p-3 d-block">
              <input
                accept="video/*"
                onChange={event => {setFile(event.target.files[0]);}}
                type="file"
                className="d-none"
              />{" "}
              Select Video
            </label>
            {!!upload&&<ProgressBar className='mb-3' animated now={upload}/>}
            <button
              onClick={handleCreateChallenge}
              className="btn btn-outline-dark"
            >
              Create
            </button>
          </div>
        </Fade>
      </div>
    </>
  );
};
export default CreateChallenge;
