import React from "react";
import {NotificationContainer} from "react-notifications";
import './style/style.scss';
import 'react-notifications/lib/notifications.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from "./Components/Login";
import CreateSeason from "./Components/CreateSeason";
import CreateChallenge from "./Components/CreateChallenge";
import StudentsList from "./Components/StudentsList";
import UsersList from "./Components/UsersList";
import Report from "./Components/Report";
function App() {
  return (
    <>
      <NotificationContainer/>
      <Router>
      <Switch>
        <Route path="/login">
          <Login/>
        </Route>
        <Route path="/createSeason">
          <CreateSeason/>
        </Route>
        <Route path="/createChallenge">
          <CreateChallenge/>
        </Route>
        <Route path="/studentsList">
          <StudentsList/>
        </Route>
        <Route path="/users">
          <UsersList/>
        </Route>
        <Route path="/report">
          <Report/>
        </Route>
        <Route path="*">
          <Login/>
        </Route>
      </Switch>
    </Router></>
  );
}

export default App;
