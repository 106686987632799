import {baseURL} from '../config'

const axios = require('axios');

export const httpCall = ({url, data, params, options = {method: 'POST'}}) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (options.headers) {
      options.headers.Authorization = `Bearer ${token}`
    } else {
      options.headers = {Authorization: `Bearer ${token}`}
    }

  }
  return axios({
    baseURL,
    url,
    data,
    params,
    ...options,
  }).catch((res) => {
    if (res.request.status === 401) {
      localStorage.removeItem('token');
      window.location.reload();
    }
    throw res;
  });
};