import React, {useEffect, useState} from "react";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";

import {NotificationManager} from "react-notifications";
import {httpCall} from "../services/httpCall";
import Header from "./Header";

const CreateSeason=()=>{
  const router = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      router.push("/login");
    }
  }, []);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const handleCreateSeason = () => {
    if (name && description) {
      return httpCall({ url: "admin/seasons", data: { name, description } }).then(() => {
        router.push("/studentsList");
      });
    }
    NotificationManager.warning("Name and Description are required", "Error");
  };
  return <>
  <div className="container-login100 align-items-baseline">
    <Header/>
    <Fade top cascade><div className="wrap-login100 text-center">
      <h3 className='bangers'>Create Season</h3>
      <input
        value={name}
        onChange={e => setName(e.target.value)}
        type="text"
        className="form-control mb-3"
        placeholder="Name"
      />
      <textarea
        value={description}
        onChange={e => setDescription(e.target.value)}
        rows={4}
        className="form-control mb-3 no-resize"
        placeholder="Description"
      />
      <button onClick={handleCreateSeason} className="btn btn-outline-dark">
        Create
      </button>
    </div>
    </Fade>
  </div></>
};
export default CreateSeason;