import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Fade from 'react-reveal/Fade';

import { NotificationManager } from "react-notifications";
import axios from "axios";
import {baseURL} from '../config'

const Login = () => {
  const router = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      router.push("/studentsList");
    }
  }, []);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = () => {
    if (email && password) {
      return axios({baseURL,url: "admin/login", data: { email, password },method:'POST' }).then(res => {
        localStorage.setItem("token", res.data.token);
        router.push("/studentsList");
      }).catch(({response})=>{
        NotificationManager.error(response.data.message,'Error')
      });
    }
    NotificationManager.warning("Email and password are required", "Error");
  };
  const handleEnter=(event)=>{
    if(event.key === 'Enter'){
      handleLogin()
    }
  }
  return (
    <div className="container-login100">
      <Fade top cascade><div className="wrap-login100 text-center">
        <h3 className='bangers'>Sign In</h3>
          <input
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="text"
            className="form-control mb-3"
            placeholder="Email"
            onKeyPress={handleEnter}
          />
          <input
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            className="form-control mb-3"
            placeholder="Password"
            onKeyPress={handleEnter}
          />
          <button onClick={handleLogin} type='button' className="btn btn-outline-dark">
            Log In
          </button>
      </div>
        </Fade>
    </div>
  );
};
export default Login;
