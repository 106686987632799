import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Table from 'react-bootstrap/Table'
import Header from "./Header";
import {httpCall} from "../services/httpCall";

const UsersList=()=>{
  const router = useHistory();
  const[data,setData]=useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      return router.push("/login");
    }
    httpCall({url:'admin/users',options:{method:'GET'}}).then((res)=>{
      setData(res.data.users);
    })
  }, []);
      console.log(data)
  return <div className="container-login100 align-items-baseline">
    <Header/>
    <Fade top cascade><div className="wrap-login100 text-center full-page-table kids-list">
      <Table>
        <thead>
        <tr>
          <th>#</th>
          <th>Kid Picture</th>
          <th>Kid Name</th>
          <th>XP</th>
          <th>Age</th>
          <th>School</th>
          <th>email</th>
          <th>phone</th>
          <th>Nationality</th>
        </tr>
        </thead>
        <tbody>
        {data.map((item,index)=><tr key={item.email}>
          <td>{index+1}</td>
          <td><img height='35px' src={item.profile?.s3_original||'/user-placeholder.png'}
                   className='rounded-image' alt='kid image'/></td>
          <td>{item.name}</td>
          <td>{item.xp}</td>
          <td>{item.age}</td>
          <td>{item.school.name}</td>
          <td>{item.email}</td>
          <td>{item.phone}</td>
          <td>{item.nationality}</td>

        </tr>)}
        </tbody>
      </Table>
    </div>

    </Fade>
  </div>
};
export default UsersList;