import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Fade from "react-reveal/Fade";
import Table from 'react-bootstrap/Table'
import Select from "react-select";
import {AiFillStar, AiOutlineStar} from 'react-icons/ai'
import {BsInfoCircle} from 'react-icons/bs';
import Header from "./Header";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {httpCall} from "../services/httpCall";
import {NotificationManager} from "react-notifications";

const StudentsList = () => {
  const router = useHistory();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [data, setData] = useState([]);
  const [video, setVideo] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUser, setShowUser] = useState(false);
  useEffect(async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      router.push("/login");
    }
    const {data: challenges} = await httpCall({
      url: 'admin/challenges',
      options: {method: 'GET'}
    });
    const optionItems = challenges.challenges.map((item, index) => {
      return {
        label: `Week ${index + 1}`,
        value: item.id,
        bestVideoDone: item.bestVideoDone,
      }
    });
    setOptions(optionItems);
    setSelectedOption(optionItems[optionItems.length - 1]);
    console.log(optionItems[optionItems.length - 1])
    if (optionItems.length) {
      httpCall({
        url: 'admin/videos',
        params: {weekID: optionItems[optionItems.length - 1].value},
        options: {method: 'GET'}
      }).then(({data}) => {
        setData(data.videos)
      })
    }
  }, []);
  const markTopWeek = (id) => {
    httpCall({
      url: 'admin/videos/best',
      data: {videoID: id}
    }).then(({data}) => {
      NotificationManager.info('Gift email is sent to this week best video owner', 'Success');
      const newOptions = options.map((item) => {
        if (item.value === selectedOption.value) {
          item.bestVideoDone = true;
        }
        return item
      })
      httpCall({
        url: 'admin/videos',
        params: {weekID: selectedOption.value},
        options: {method: 'GET'}
      }).then(({data}) => {
        setData(data.videos)
      })
      setOptions(newOptions);
      setSelectedOption(newOptions.filter((item) => item.value === selectedOption.value)[0]);
    })
  };
  return <div className="container-login100 align-items-baseline">
    <Header/>
    <Fade top cascade>
      <div className="wrap-login100 text-center w-900 kids-list">
        <Select className={'w-25 mx-auto mb-3'} options={options}
                value={selectedOption}
                placeholder='Select Challenge' onChange={(data) => {
          setSelectedOption(data)
          httpCall({
            url: 'admin/videos',
            params: {weekID: data.value},
            options: {method: 'GET'}
          }).then(({data}) => {
            setData(data.videos)
          })
        }}/>
        <Table>
          <thead>
          <tr>
            <th>#</th>
            <th>Kid Name</th>
            <th>XP</th>
            <th>Fitness Video</th>
            <th>STEM Video</th>
            <th>Details</th>
          </tr>
          </thead>
          <tbody>
          {data.map((item, index) => {
            const stem = item.videos.filter((i) => i.type === 0)[0];
            const fitness = item.videos.filter((i) => i.type === 1)[0];
            return (<tr key={item._id.userID}>
              <td>{index + 1}</td>
              <td className='center'><img height='35px' src={item._id.profile || '/user-placeholder.png'}
                       className='rounded-image' alt='kid image'/>
                <span title={item._id.userName} className='pl-2 truncate'>{item._id.userName}</span></td>
              <td>{item._id.xp}</td>

              <td>
                {fitness ? <span onClick={() => {
                  setVideo(fitness.originalUrl)
                }} className='p-2 pointer'>Show Video</span> : 'N/A'}
                {fitness && <>{fitness.bestVideo ? <AiFillStar className={''} size={'2em'} color={'gold'}/> :
                  <AiOutlineStar className={'pointer'} onClick={(() => {
                    if (selectedOption.bestVideoDone) {
                      return NotificationManager.warning('Top Video of this week is already selected', 'Warning')
                    }
                    setShowConfirm(fitness.videoID)
                  })}
                                 size={'2em'}/>}</>}
              </td>
              <td>
                {stem ? <span onClick={() => {
                  setVideo(stem.originalUrl)
                }} className='p-2 pointer'>Show Video</span> : 'N/A'}
                {stem && <>{stem.bestVideo ? <AiFillStar className={''} size={'2em'} color={'gold'}/> :
                  <AiOutlineStar className={'pointer'} onClick={(() => {
                    if (selectedOption.bestVideoDone) {
                      return NotificationManager.warning('Top Video of this week is already selected', 'Warning')
                    }
                    setShowConfirm(stem.videoID)
                  })}
                                 size={'2em'}/>}</>}
              </td>
              <td><BsInfoCircle className={'pointer'} size={'1em'} onClick={() => {
                setShowUser(item._id)
              }}/></td>
            </tr>)
          })

          }
          </tbody>
        </Table>
        {data.length === 0 && <div className='text-center w-100'>No Data</div>}
        <Modal show={!!showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure to mark this video as top week?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirm(false)}>
              No
            </Button>
            <Button variant="primary" onClick={() => {
              markTopWeek(showConfirm)
              setShowConfirm(false);
            }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={!!showUser} onHide={() => setShowUser(false)}>
          <Modal.Header closeButton>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Name: <strong>{showUser.userName}</strong>
            </p>
            <p>
              XP: <strong>{showUser.xp}</strong>
            </p>
            <p>
              School: <strong>{showUser.school}</strong>
            </p>
            <p>
              Email: <strong>{showUser.email}</strong>
            </p>
            <p>
              Phone: <strong>{showUser.phone}</strong>
            </p>
            <p>
              Nationality: <strong>{showUser.nationality}</strong>
            </p>
            <p>
              age: <strong>{showUser.age}</strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowUser(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={!!video} onHide={() => setVideo('')}>
          <Modal.Header closeButton>
            <Modal.Title>Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video controls width="100%" height="auto">
              <source src={video}/>
            </video>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setVideo('')}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </Fade>
  </div>
};
export default StudentsList;